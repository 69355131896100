<app-page-container #pageContainer
  [pageTitleParts]="[ 'Dashboard' ]"
  [actions]="[
    { key: 'togglePanels', icon: 'eye-open.svg', name: 'dashboard.actions.togglePanels', menuRef: menu },
    { key: 'toggleLegend', icon: 'eye-open.svg', name: 'dashboard.actions.hideLegend', activeIcon: 'eye-closed.svg', activeName: 'dashboard.actions.showLegend' },
    autoUpdateAction,
  ]"
  (actionClicked)="onActionClicked($event)"
  [headerContent]="headerContent"
>
  <ng-template #headerContent>
    <div class="view-selector">
      <div class="view-item" *ngFor="let view of views; let index = index;" 
        [class.active]="selectedIndex === index" 
        (click)="selectedIndex = index; timeWhenSwitchedView = dateNow">
        <span>{{ view.name }}</span>
      </div>
      <button (click)="addItemToLayout(views[selectedIndex], 'q1')">ADD ELEMENT</button>
      <span class="view-indicator" [style.left.px]="200 * selectedIndex + 100"></span>
    </div>
  </ng-template>

  <div #stepper>
    <mat-stepper [linear]="false" [(selectedIndex)]="selectedIndex">
      <mat-step *ngFor="let view of views; let v_i = index">
        <ktd-grid *ngIf="selectedIndex === v_i"
          compactType="vertical"
          [cols]="view.cols"
          [rowHeight]="stepper ? (stepper.clientHeight / rows) : 100"
          [layout]="view.layout"
          (layoutUpdated)="view.layout = $event; onLayoutUpdated(view, $event)">
          <ktd-grid-item *ngFor="let item of view.layout; let i_i = index; trackBy:trackGridItem" 
            transition="transform 200ms ease, width 200ms linear, height 200ms linear"
            draggable="true"
            resizable="true"
            [id]="item.id">
            <app-panel class="grid-item-content" [class.highlight]="graphs.q1.highlight === true">
              <h3 class="panel-title">{{  "dashboard.q1Title" | translate }} - <b>{{ graphs.q1.total }} {{ item.id }}</b></h3>
              <div style="position: absolute; top: 17px; right: 17px;">
                <mat-button-toggle-group [(value)]="graphs.q1.showPieChart">
                  <mat-button-toggle [value]="false">
                    <img class="selector-icon" src="/assets/icons/istogram.svg" alt="istogram chart">
                  </mat-button-toggle>
                  <mat-button-toggle [value]="true">
                    <img class="selector-icon" src="/assets/icons/piechart.svg" alt="pie chart">
                  </mat-button-toggle>
                </mat-button-toggle-group>  
              </div>
              <div class="graph-container" *ngIf="(timeWhenSwitchedView + 500 + 500*i_i) < dateNow" [ngSwitch]="graphs.q1.showPieChart">
                <app-vertical-bars-chart  *ngSwitchCase="false"
                  hideXAxisTicks
                  [showGridLines]="true"
                  [roundEdges]="true"
                  [xAxis]="true"
                  [yAxis]="true"
                  [showDataLabel]="true"
                  [maxXAxisTickLength]="100"
                  [yScaleMin]="0"
                  [yScaleMax]="0"
                  [customColors]="graphs.q1.colors"
                  [results]="graphs.q1.dataset"
                ></app-vertical-bars-chart>
                <ngx-charts-pie-chart *ngSwitchCase="true"
                  [doughnut]="true"
                  [labels]="true"
                  [labelFormatting]="graphs.q1.labelFormatting"
                  [trimLabels]="false"
                  [customColors]="graphs.q1.colors"
                  [results]="graphs.q1.dataset"
              ></ngx-charts-pie-chart>
              </div>
            </app-panel>
            <button
              mat-raised-button
              color="primary"
              type="button"
              class="grid-item-remove-handle"
              (mousedown)="$event.preventDefault(); $event.stopPropagation()"
              (click)="removeItem(view, item.id)">
              
              <img src="/assets/icons/delete-white.svg" alt="remove" >
            </button>
          </ktd-grid-item>
        </ktd-grid>
      </mat-step>
    </mat-stepper>
  </div>
</app-page-container>



<!-- <div class="main-column" *ngIf="selectedIndex === 0">
  <app-panel [open]="showLegend" class="p-1" [class.highlight]="graphs.q1.highlight === true">
    <div class="legend" [class.hidden]="showLegend === false">
      <div class="legend-item" *ngFor="let item of legend">
        <div class="color" [style.backgroundColor]="item.value"></div>
        <span>{{ item.name }}</span>
      </div>
    </div>
  </app-panel>
  <div class="row">
    <div class="col" [@panelState]="(graphs.q1.isVisible || graphs.q3.isVisible) ? 'open' : 'closed' ">
      <app-panel [open]="graphs.q1.isVisible" class="p-1" [class.highlight]="graphs.q1.highlight === true">
        <h3 class="panel-title">{{  "dashboard.q1Title" | translate }} - <b>{{ graphs.q1.total }}</b></h3>
        <div style="position: absolute; top: 17px; right: 17px;">
          <mat-button-toggle-group [(value)]="graphs.q1.showPieChart">
            <mat-button-toggle [value]="false">
              <img class="selector-icon" src="/assets/icons/istogram.svg" alt="istogram chart">
            </mat-button-toggle>
            <mat-button-toggle [value]="true">
              <img class="selector-icon" src="/assets/icons/piechart.svg" alt="pie chart">
            </mat-button-toggle>
          </mat-button-toggle-group>  
        </div>
        <div class="graph-container" [ngSwitch]="graphs.q1.showPieChart">
          <app-vertical-bars-chart  *ngSwitchCase="false"
            hideXAxisTicks
            [showGridLines]="true"
            [roundEdges]="true"
            [xAxis]="true"
            [yAxis]="true"
            [showDataLabel]="true"
            [maxXAxisTickLength]="100"
            [yScaleMin]="0"
            [yScaleMax]="0"
            [customColors]="graphs.q1.colors"
            [results]="graphs.q1.dataset"
          ></app-vertical-bars-chart>
          <ngx-charts-pie-chart *ngSwitchCase="true"
            [doughnut]="true"
            [labels]="true"
            [labelFormatting]="graphs.q1.labelFormatting"
            [trimLabels]="false"
            [customColors]="graphs.q1.colors"
            [results]="graphs.q1.dataset"
        ></ngx-charts-pie-chart>
        </div>
      </app-panel>
      <app-panel [open]="graphs.q3.isVisible" class="p-3" [class.highlight]="graphs.q3.highlight === true">
        <h3 class="panel-title">{{  "dashboard.q3Title" | translate }} -  <b>{{ graphs.q3.total }}</b></h3>
        <div style="position: absolute; top: 17px; right: 17px;">
          <mat-button-toggle-group [(value)]="graphs.q3.showPieChart">
            <mat-button-toggle [value]="false">
              <img class="selector-icon" src="/assets/icons/istogram.svg" alt="istogram chart">
            </mat-button-toggle>
            <mat-button-toggle [value]="true">
              <img class="selector-icon" src="/assets/icons/piechart.svg" alt="pie chart">
            </mat-button-toggle>
          </mat-button-toggle-group>  
        </div>
        <div class="graph-container" [ngSwitch]="graphs.q3.showPieChart">
          <app-vertical-bars-chart *ngSwitchCase="false"
            hideXAxisTicks
            [showGridLines]="true"
            [roundEdges]="true"
            [xAxis]="true"
            [maxXAxisTickLength]="100"
            [showDataLabel]="true"
            [yAxis]="true"
            [customColors]="graphs.q3.colors"
            [results]="graphs.q3.dataset"
          ></app-vertical-bars-chart>
          <ngx-charts-pie-chart *ngSwitchCase="true"
            [doughnut]="true"
            [labels]="true"
            [labelFormatting]="graphs.q3.labelFormatting"
            [trimLabels]="false"
            [customColors]="graphs.q3.colors"
            [results]="graphs.q3.dataset"
          ></ngx-charts-pie-chart>
        </div>
      </app-panel>
    </div>
    <div class="col" [@panelState]="(graphs.q2.isVisible || graphs.q4.isVisible || graphs.q5.isVisible) ? 'open' : 'closed' ">
      <app-panel [open]="graphs.q2.isVisible" class="p-2" [class.highlight]="graphs.q2.highlight === true">
        <h3 class="panel-title">{{  "dashboard.q2Title" | translate }}</h3>
        <div class="graph-container">
          <app-vertical-bars-chart
            hideXAxisTicks
            [showGridLines]="true"
            [roundEdges]="true"
            [maxXAxisTickLength]="100"
            [maxYAxisTickLength]="50"
            [showDataLabel]="true"
            [xAxis]="true"
            [yAxis]="true"
            [customColors]="graphs.q2.colors"
            [results]="graphs.q2.dataset"
          ></app-vertical-bars-chart>
        </div>
      </app-panel>
      <app-panel [open]="graphs.q4.isVisible" class="p-4" [class.highlight]="graphs.q4.highlight === true">
        <h3 class="panel-title">{{  "dashboard.q4Title" | translate }}</h3>
        <div class="graph-container">
          <app-vertical-bars-chart
            hideXAxisTicks
            [showGridLines]="true"
            [roundEdges]="true"
            [xAxis]="true"
            [showDataLabel]="true"
            [yAxis]="true"
            [customColors]="graphs.q4.colors"
            [results]="graphs.q4.dataset"
          ></app-vertical-bars-chart>
        </div>
      </app-panel>
      <app-panel [open]="graphs.q5.isVisible" class="p-5" [class.highlight]="graphs.q5.highlight === true">
        <h3 class="panel-title">{{  "dashboard.q5Title" | translate }}</h3>
        <div class="graph-container">
          <app-vertical-bars-chart
            hideXAxisTicks
            [showGridLines]="true"
            [roundEdges]="true"
            [xAxis]="true"
            [showDataLabel]="true"
            [yAxis]="true"
            [customColors]="graphs.q5.colors"
            [results]="graphs.q5.dataset"
          ></app-vertical-bars-chart>
        </div>
      </app-panel>
    </div>
  </div>
</div> -->

<mat-menu #menu="matMenu">
  <div class="menu-item" (mouseover)="toggleHighlight($any(item.key), true)" (mouseout)="toggleHighlight($any(item.key), false)" (click) = "$event.stopPropagation()" *ngFor="let item of graphs | keyvalue">
    <!--//@ts-ignore -->
    <mat-checkbox [ngModel]="item.value.isVisible" (ngModelChange)="togglePanel($any(item.key), $event)">{{ ("dashboard." + item.key + "Title") | translate }}</mat-checkbox>
  </div>
</mat-menu>