import { Location } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { ActivatedRouteSnapshot, EventType, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ROUTES } from 'src/app/app-routing.module';
import { ERole } from 'src/app/models/user';
import { InternationalizationService } from 'src/app/services/internationalization/internationalization.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserService } from 'src/app/services/user/user.service';

export interface IRoute {
  icon?: string,
  name: string,
  path?: string,
  subroutes?: IRoute[],
  hasSubroutes: boolean,
}

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent {

  hideSideMenu: boolean = false;

  selectedRouteUrl: string = '';
  selectedRoute: ActivatedRouteSnapshot;
  selectedLang: string = '';

  routes: IRoute[] = this.getRoutes();

  constructor(
    private router: Router,
    public internationalizationService: InternationalizationService,
    private translateService: TranslateService,
    public userService: UserService,
    public sharedService: SharedService,
  ) { }

  ngOnInit(): void {

    this.sharedService.isUsingDarkTheme = this.userService.getSetting("theme") === "dark";

    this.selectedRoute = this.router.routerState.snapshot.root.firstChild;
    this.selectedLang = this.internationalizationService.currentLang;

    this.router.events.subscribe((val) => {
      switch (val.type) {
        case EventType.NavigationEnd:
          this.selectedRouteUrl = val.url.split("?")[0];
          break;
        case EventType.ActivationStart:
          this.selectedRoute = val.snapshot;
          break;
      }
    });

    this.internationalizationService.onLangChange.subscribe((val) => {
      this.selectedLang = val.lang;
    });

    this.userService.userChanged.subscribe(() => {
      this.routes = this.getRoutes();
    });
  }

  goToLogin() {
    this.router.navigate(["/login/loggedOut"]);
  }

  getTribes() {
    if (this.userService.tribes && this.userService.tribes.length) 
      return this.userService.tribes.map(tr => `(${tr.businessLine}) ${tr.tribe}`).join(", ");
    return "";
  }

  private getRoutes() {
    return ROUTES.reduce((p, c) => {

      if (c.data && (
          c.data["hidden"] || 
          (c.data["allowedRoles"] && !c.data["allowedRoles"].some((r: ERole) => this.userService.role == r))
        )) return p;

      const children = c.children ? c.children.filter(c => !c.data || c.data["hidden"] != true) : [];
      const subroutes = children.reduce((p, sr) => {

        if (sr.data && 
          (sr.data["allowedRoles"] && !sr.data["allowedRoles"].some((r: ERole) => this.userService.role == r))
        ) return p;

        p.push({
          name: sr.path || "",
          icon: sr.path || "",
          path: "/" + c.path + "/" + sr.path,
        });

        return p;
      }, []);

      if (!children.length || subroutes.length > 0)
        p.push({      
          name: c.path || "",
          icon: c.path || "",
          path: "/" + c.path,
          subroutes: subroutes,
          hasSubroutes: subroutes && subroutes.length > 0,
        });

      return p;
    }, [] as IRoute[]);
  }

  isRouteSelected(route: IRoute) {
    if (route.hasSubroutes) {
      return route.subroutes.some(subroute => subroute.path === this.selectedRouteUrl);
    }
    return route.path === this.selectedRouteUrl;
  }

  navigate(route: IRoute) {
    if (!route.path) return;

    this.router.navigate([route.path]);
  }

/*   @HostListener('window:popstate', ['$event'])
  onPopState(event: Event) {
    if (this.selectedRoute.data && this.selectedRoute.data["showAlertOnBackNavigation"]) {
      this.sharedService.showBackNavigationAlert(() => {
        this.location.back();
      }, "common.actions.goBack");
    } else {
      this.location.back();
    }
  } */

  getTitle(routename: string) {
    const key = routename + ".title";
    const translation = this.translateService.instant(key);
    return key === translation ? routename : translation;
  }

  onThemeChange() {
    if (this.sharedService.isUsingDarkTheme)
      document.documentElement.classList.remove("dark-theme");
    else
      document.documentElement.classList.add("dark-theme");   
     
    this.sharedService.isUsingDarkTheme = !this.sharedService.isUsingDarkTheme;
    this.userService.saveSetting("theme", this.sharedService.isUsingDarkTheme ? "dark" : "light");
  }


}
