import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgxChartsModule }from '@swimlane/ngx-charts';

import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { ErrorStateMatcher, MatRippleModule, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { AuthInterceptor } from './services/api/auth-interceptor';
import { TouchedErrorStateMatcher } from './services/shared/shared.service';

import * as PIPES from './pipes/index';

import * as DIRECTIVES from './directives/index';

import * as COMPONENTS from './components/index';

import * as PAGES from './pages/index';
import { CustomMatPaginatorIntl } from './services/internationalization/matPaginatorIntl';
import { KtdGridModule } from '@katoid/angular-grid-layout';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,

    // ----------------- DIRECTIVES -----------------

    PIPES.PluralTranslatePipe,

    // ----------------- DIRECTIVES -----------------

    DIRECTIVES.TooltipNoHover,
    
    // ----------------- COMPONENTS -----------------
    
    COMPONENTS.VerticalBarsChartComponent,
    COMPONENTS.LineChartComponent,
    COMPONENTS.DatepickerHeaderComponent,
    COMPONENTS.SidemenuComponent,
    COMPONENTS.PageContainerComponent,
    COMPONENTS.PanelComponent,
    COMPONENTS.AlertComponent,
    COMPONENTS.RatingViewComponent,
    COMPONENTS.FieldErrorComponent,
    COMPONENTS.TableCellEllipsisComponent,
    COMPONENTS.AccordionIconComponent,
    COMPONENTS.AccordionPanelComponent,
    COMPONENTS.StepperHeaderComponent,
    COMPONENTS.RatingFormComponent,
    COMPONENTS.ProjectTimingComponent,
    COMPONENTS.RatingHistoryPanelComponent,
    COMPONENTS.ImageViewerComponent,
    COMPONENTS.ProcedureViewComponent,
    COMPONENTS.CircleSeriesComponent,
    COMPONENTS.LineSeriesComponent,
    COMPONENTS.LineComponent,

    // ----------------- PAGES -----------------

    PAGES.HomeComponent,
    PAGES.LoginComponent,
    PAGES.DashboardComponent,
    PAGES.ErrorComponent,

    PAGES.UsersListComponent,
    PAGES.UserEditComponent,

    PAGES.SuppliersListComponent,
    PAGES.SupplierEditComponent,
    PAGES.SupplierPanelComponent,
    
    PAGES.RequestsListComponent,
    PAGES.RequestWizardComponent,
    PAGES.RequestSummaryComponent,

    PAGES.ProjectsListComponent,
    PAGES.ProjectWizardComponent,
    PAGES.ProjectEditComponent,
    PAGES.ProjectPanelComponent,
    PAGES.RatingComponent,

    PAGES.ProceduresComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  
    KtdGridModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatInputModule,
    MatDialogModule,
    MatBadgeModule,
    MatMenuModule,
    MatTooltipModule,
    MatRippleModule,
    OverlayModule,
    MatTableModule,
    MatCheckboxModule,
    NgxChartsModule,
    MatStepperModule,
    MatSortModule,
    MatSlideToggleModule,
    DragDropModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDatepickerModule,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'} },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorStateMatcher, useClass: TouchedErrorStateMatcher },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
